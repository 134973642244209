.footer {
  background: #f4aa00;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-inner {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.footer-inner2 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.footer-inner3 {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 90px;
}

.up {
  width: 100px;
  margin-top: 70px;
}

.footer2 {
  width: 50%;
}

.social {
  width: 220px;
  display: flex;
  justify-content: space-between;
  margin-left: 140px;
  margin-top: 24px;
}

.footer-text1 {
  margin-left: 140px;
  margin-top: 24px;
}

.footer-text2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1f1f1f;
  margin-top: 22px;
}

.footer-text3 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #1f1f1f;
  margin-top: 8px;
}

.footer-text4 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1f1f1f;
}

.not {
  margin-top: 140px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 100px;
}

.not-text1 {
  font-family: "Bodoni MT";
  font-style: italic;
  font-weight: 700;
  font-size: 64px;
  color: #1b1c1e;
  margin-bottom: 56px;
}

.not-text2 {
  font-family: "Bodoni MT";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #1b1c1e;
  margin-bottom: 20px;
}

.not-text3 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #1f1f1f;
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .footer-inner {
    width: calc(100% - 48px);
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }

  .footer-inner2 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
  }

  .footer-inner3 {
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    margin-top: 32px;
  }

  .social {
    width: 220px;
    display: flex;
    justify-content: space-between;
    margin-left: 24px;
    margin-top: 24px;
  }

  .footer-text1 {
    margin-left: 24px;
    margin-top: 24px;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .footer2 {
    width: 100%;
  }
}
