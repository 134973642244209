.body {
  font-family: "Roboto", sans-serif;
  color: white;
}

.body1 {
  background-color: #1f1f1f;
}

@font-face {
  font-family: "Trueno";
  src: local("TruenoBd"), url(./fonts/trueno/TruenoBd.otf) format("truetype");
}

@font-face {
  font-family: "TruenoBlk";
  src: local("TruenoBlk"), url(./fonts/trueno/TruenoBlk.otf) format("truetype");
}

@font-face {
  font-family: "TruenoBlkOl";
  src: local("TruenoBlkOl"),
    url(./fonts/trueno/TruenoBlkOl.otf) format("truetype");
}

a {
  text-decoration: none;
}

.rm {
  text-decoration: none;
  color: white !important;
}

.rm1:hover {
  text-decoration: none;
  color: #1f1f1f !important;
}

.side_text2:hover {
  color: #f4aa00 !important;
}

.comp-item:hover {
  color: #f4aa00 !important;
}

.side_text3:hover {
  color: #f4aa00 !important;
}

.header-text1:hover {
  color: #f4aa00 !important;
  text-decoration: none !important;
}

.rm:hover {
  text-decoration: none !important;
}

.white {
  color: white !important;
}

.header-text-about {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1b1c1e;
  padding-top: 15px;
}

.headerb {
  background-color: white;
  height: 95px;
  mix-blend-mode: normal;
}

a.active {
  color: orange !important;
}

a.normal {
  color: #919191;
}

.social {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.about-section1 {
  display: flex;
  justify-content: space-between;
}

.tag {
  font-weight: 500;
  text-shadow: 2px 2px #413c3c;
}

.baker {
  font-size: 60px;
  text-align: center;
  color: white;
  margin-bottom: 5px;
  text-shadow: 2px 2px #413c3c;
}

.parallax {
  /* The image used */
  background-size: cover;
  height: 100vh;
  width: 100%;
  animation: slide 60s infinite;
}

.arr {
  width: 50px;
  position: absolute;
  top: 0px;
  left: 5px;
}

.arr1 {
  width: 50px;
  position: absolute;
  top: 0px;
  right: 5px;
}

.section1-header {
  margin: 0px;
  font-size: 24px;
  font-weight: 500;
}

.box {
  width: 300px;
  height: 200px;
  background-color: blue;
}

.box1 {
  width: 400px;
  height: 400px;
  background-color: #61dafb;
  margin-bottom: 30px;
}

.wallet {
  display: block;
  margin-right: 100px;
  margin-top: 10px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  background-color: orange;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  color: white;
}

.ham {
  display: none;
}

/* @media (max-width: 800px) {
  .animation1 {
    transform: translateY(30px);
    transition: transform 1.5s;
  }
  .header-inner {
    display: block;
    position: absolute;
    z-index: 0;
    top: 100px;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .ham {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 19px;
  }

  .header {
    justify-content: space-between;
  }

  .wallet {
    margin-right: 10px;
  }
  .animation2 {
    transform: translateY(-400px);
    transition: transform 1.5s;
  }
}

@media (max-width: 400px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer2 {
    margin-bottom: 20px;
  }

  .footer3 {
    margin-bottom: 20px;
  }
}

@media (max-width: 300px) {
  .box1 {
    width: 300px;
  }

  .baker {
    font-size: 40px;
  }
}

@media only screen and (max-device-width: 400px) {
  .parallax {
    background-attachment: inherit;
  }
} */

/* Header */
