.header {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.head {
  background: #f9eded;
  padding-top: 13px;
  padding-bottom: 13px;
  filter: drop-shadow(4px 4px 32px rgba(0, 0, 0, 0.16));
  position: relative;
  z-index: 1;
}

.headerInner {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.headerItem {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--ibm-font);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1f1f1f;
  padding-left: 20px;
}

.ham {
  display: none;
}

@media (max-width: 500px) {
  .headerInner {
    display: block;
    padding-left: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    height: 250px;
    position: absolute;
    top: 80px;
    left: 0px;
    background-color: #333333;
    z-index: 100;
  }

  .headerItem {
    font-size: 24px;
    color: #f9eded;
    margin-bottom: 40px;
  }

  .animation1 {
    transform: translateX(0);
    transition: transform 1.5s;
  }

  .animation2 {
    transform: translateX(-1000px);
    transition: transform 1.5s;
  }

  .ham {
    display: block;
  }

  .logo {
    width: 50px;
  }
}
