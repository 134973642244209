.section1-story {
  background: #181d29;
  height: 350px;
  margin-top: 100px;
}

.ann {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 450px;
  position: relative;
  top: -30px;
}

.story-text1 {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  font-size: 64px;
  color: #1f1f1f;
  text-align: center;
}

.section2-story-inner {
  margin-top: 70px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 120px;
}

.section2-story {
  background: #f9eded;
  padding-top: 100px;
}

.story-box {
  border-top: 2px solid #e0d7d7;
  border-bottom: 2px solid #e0d7d7;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
}

.story-box1 {
  border-bottom: 2px solid #e0d7d7;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
}

.story-text2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: rgba(51, 51, 51, 0.96);
}

.story-text3 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: rgba(51, 51, 51, 0.96);
  width: 50%;
}

@media (max-width: 500px) {
  .ann {
    width: 70%;
  }

  .section1-story {
    background: #181d29;
    height: 200px;
    margin-top: 100px;
  }

  .story-text1 {
    font-size: 24px;
    text-align: center;
  }

  .section2-story {
    background: #f9eded;
    padding-top: 50px;
  }

  .story-box {
    padding-top: 32px;
    padding-bottom: 32px;
    flex-direction: column;
  }

  .story-box1 {
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .story-text2 {
    font-size: 32px;
  }

  .story-text3 {
    font-size: 15px;
    color: rgba(51, 51, 51, 0.96);
    width: 100%;
    margin-top: 8px;
  }
}
