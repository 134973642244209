.section1-about {
  background: #f9eded;
}

.section1-about-inner1 {
  padding-top: 64px;
  padding-left: 140px;
}

.about-text1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #1f1f1f;
}

.about-text2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #1f1f1f;
  margin-top: 32px;
  width: 75%;
  padding-bottom: 64px;
}

.section1-about-inner2 {
  height: 400px;
  background: #f4aa00;
  padding-bottom: 50px;
}

.img7 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 500px;
  position: relative;
  top: -100px;
  margin-top: 100px;
}

.section2-about {
  background: #333333;
}

.section2-about-inner {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 120px;
  padding-bottom: 120px;
}

.about-text3 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #f9eded;
  width: 80%;
}

.about-box {
  border-left: 4px solid #f9eded;
  padding-left: 16px;
  margin-top: 48px;
}

.about-text4 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #f9eded;
  margin-bottom: 32px;
}

.about-text5 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #f9eded;
}

.section3-about {
  background: #f9eded;
}

.section3-about-inner {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
}

.about-text6 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #1f1f1f;
}

.lin {
  display: block;
  margin-top: 32px;
}

.about-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 64px;
}

.about-text7 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #333333;
  margin-top: 16px;
}

.about-text8 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-top: 8px;
  color: #333333;
}

.about-box2 {
  margin-bottom: 100px;
}

@media (max-width: 500px) {
  .section1-about-inner1 {
    padding-top: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .about-text1 {
    font-size: 36px;
  }

  .about-text2 {
    font-size: 15px;
    color: #1f1f1f;
    margin-top: 16px;
    width: 100%;
    padding-bottom: 24px;
    line-height: 1.5rem;
  }

  .img7 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 300px;
    position: relative;
    top: -100px;
    margin-top: 100px;
  }

  .section1-about-inner2 {
    height: 250px;
    padding-bottom: 0px;
  }

  .about-text6 {
    font-size: 36px;
  }

  .section3-about-inner {
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 64px;
  }

  .person {
    width: 100%;
  }
  .about-box2 {
    margin-bottom: 32px;
  }

  .section2-about-inner {
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 47px;
    padding-bottom: 48px;
  }

  .about-text3 {
    font-size: 24px;
    color: #f9eded;
    width: 100%;
  }

  .about-text4 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .about-text5 {
    font-size: 15px;
  }
}
