.contact-section1 {
  background: url("../../public/images/bg2.png");
  background-size: cover;
  height: 40vh;
}

.contact-section2 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 120px;
}

.contact-text1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  color: #1f1f1f;
  padding-top: 32px;
}

.contact-text2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #1f1f1f;
  padding-top: 24px;
}

.contact-section2-inner {
  display: flex;
  padding-top: 56px;
}

.contact-text3 {
  font-family: "Bodoni MT";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #1b1c1e;
  padding-bottom: 32px;
}

.input-box {
  margin-bottom: 32px;
}

.input-box2 {
  margin-bottom: 48px;
}

.label {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #1f1f1f;
  display: block;
  padding-bottom: 16px;
}

.input {
  border: 1px solid #333333;
  border-radius: 8px;
  width: calc(90% - 15px);
  height: 64px;

  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  background: transparent;
  color: #1f1f1f;
  padding-left: 15px;
}

.textarea {
  border: 1px solid #333333;
  border-radius: 8px;
  width: calc(90% - 15px);
  height: 150px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  background: transparent;
  color: #1f1f1f;
  padding-left: 15px;
  padding-top: 10px;
}

.cbut {
  width: 90%;
  height: 63px;
  background: #f4aa00;
  border-radius: 8px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1f1f1f;
  border: none;
}

.contact-social {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.contact-text4 {
  font-family: "Bodoni MT";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #1f1f1f;
}

.contact-text5 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #1f1f1f;
  margin-top: 8px;
}

.whflex {
  width: 90%;
  display: flex;
}

@media (max-width: 500px) {
  .contact-section2-inner {
    flex-direction: column-reverse;
    padding-top: 56px;
  }

  .contact-text1 {
    font-size: 36px;
    padding-top: 32px;
  }

  .contact-text2 {
    font-size: 15px;
    padding-top: 24px;
  }

  .contact-text3 {
    font-size: 24px;
    padding-bottom: 32px;
    padding-top: 30px;
  }

  .contact-text4 {
    font-size: 24px;
  }

  .contact-text5 {
    font-size: 16px;
    margin-top: 8px;
  }
}
