.main-rm {
  background: #f9eded;
}

.rm-text1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  color: #1f1f1f;
  padding-top: 48px;
  text-align: center;
  padding-bottom: 64px;
}

.rmbox1 {
  border: 4px solid #f4aa00;
  border-radius: 8px;
  width: 500px;
  padding: 20px;
  margin-bottom: 100px;
}

.rmbox2 {
  border: 4px solid #00f47f;
  border-radius: 8px;
  width: 500px;
  padding: 20px;
  margin-bottom: 100px;
}

.rmbox3 {
  border: 4px solid #e000f4;
  border-radius: 8px;
  width: 500px;
  padding: 20px;
  margin-bottom: 100px;
}

.rmbox4 {
  border: 4px solid #0070f4;
  border-radius: 8px;
  width: 500px;
  padding: 20px;
  margin-bottom: 100px;
}

.rminner {
  background: #333333;
  border-radius: 100px;
  width: 150px;
  height: 80px;
  position: relative;
  margin-top: -60px;
  margin-left: auto;
  margin-right: auto;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  color: #f4aa00;
  text-align: center;
}

.rm-text2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #1f1f1f;
}

.ul {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #1f1f1f;
  padding-left: 20px;
}

.rmflex {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .rm-text1 {
    font-size: 36px;
    padding-top: 48px;
    padding-bottom: 32px;
  }

  .rminner {
    background: #333333;
    border-radius: 100px;
    width: 150px;
    height: 60px;
    position: relative;
    padding-top: 10px;
    margin-top: -60px;
    font-size: 32px;
  }

  .rmbox1 {
    border: 4px solid #f4aa00;
    border-radius: 8px;
    width: 500px;
    padding: 20px;
    margin-bottom: 100px;
    margin-top: 50px;
  }

  .rm-text2 {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #1f1f1f;
    padding-top: 30px;
  }

  li {
    font-size: 15px;
  }
}
