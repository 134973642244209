.section1-home {
  background-image: url("../../public/images/bg.png");
  height: 100vh;
  background-size: cover;
}

.section1-home-inner {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-top: 74px;
}

.home-text1 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  color: #1f1f1f;
  margin-top: 150px;
}

.section2-home {
  background: #333333;
}

.home-text2 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #f9eded;
  padding-left: 140px;
  padding-top: 127px;
  padding-bottom: 127px;
  width: 50%;
}

.section3-home {
  background: #f9eded;
  padding-top: 0px;
  padding-bottom: 100px;
}

.section3-home-inner1 {
  display: flex;
  padding-left: 140px;
}

.img1 {
  width: 50%;
}

.home-text3 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: #333333;
  margin-top: 200px;
}

.home-text4 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #1f1f1f;
  margin-top: 24px;
}

.home-text5 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #1f1f1f;
  margin-top: 24px;
}

.home-but1 {
  width: 312px;
  height: 64px;
  background: #f4aa00;
  border-radius: 8px;
  margin-top: 32px;
  border: none;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1f1f1f;
}

.home-text6 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  color: #1f1f1f;
  text-align: center;
}

.section3-home-inner2 {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
}

.home-text7 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #1f1f1f;
  margin-top: 24px;
}

.home-but2 {
  width: 312px;
  height: 64px;
  background: #f4aa00;
  border-radius: 8px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  border: none;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #1f1f1f;
}

.img-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 100px;
}

.block {
  display: block;
}
.block1 {
  display: block;
  margin-bottom: 30px;
}

.img2,
.img5 {
  width: 400px;
  height: 50%;
}

.section3-home-inner3 {
  margin-top: 100px;
  display: flex;
}

.home-box {
  margin-left: 75px;
}

.home-text8 {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  color: #1f1f1f;
  margin-top: 50px;
  width: 70%;
}

.home-text9 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #1f1f1f;
  margin-top: 24px;
  width: 70%;
}

.home-but3 {
  width: 312px;
  height: 64px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: none;
  color: #1f1f1f;
  background: #f4aa00;
  border-radius: 8px;
  margin-top: 32px;
}

.section4-home {
  background: #333333;
}

.home-box2 {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 57px;
  padding-bottom: 57px;
}

.home-text10 {
  font-family: "Bodoni MT";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  text-align: center;
  color: #f9eded;
}

.home-but4 {
  width: 312px;
  height: 64px;

  background: #f4aa00;
  border-radius: 8px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  border: none;
  color: #1f1f1f;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (max-width: 1200px) {
  .home-text1 {
    margin-top: 30px;
    text-align: center;
  }

  .img-flex {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .img {
    width: 100%;
    height: 500px;
  }

  .img2,
  .img5 {
    width: 100%;
    height: 500px;
  }

  .block1 {
    margin-bottom: 0px;
  }

  .section1-home-inner {
    width: 80%;
    flex-direction: column;
    padding-top: 0px;
    margin-left: auto;
    margin-right: auto;
  }

  .bg1 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1000px) {
  .section3-home-inner3 {
    flex-direction: column;
  }

  .img6 {
    width: 80%;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 500px) {
  .section1-home {
    height: 80vh;
  }

  .home-text1 {
    font-size: 36px;
    margin-top: 48px;
    margin-bottom: 30px;
  }

  .bg1 {
    width: 100%;
  }

  .section1-home-inner {
    width: 80%;
    flex-direction: column;
    padding-top: 0px;
  }

  .img1 {
    width: 100%;
  }

  .section3-home-inner1 {
    flex-direction: column;
    padding-left: 0px;
  }

  .home-text2 {
    font-size: 15px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 29px;
    padding-bottom: 29px;
    width: calc(100% - 48px);
  }

  .home-text3 {
    font-size: 14px;
    margin-top: 32px;
    padding-left: 24px;
  }

  .home-text4 {
    font-size: 24px;
    margin-top: 16px;
    padding-left: 24px;
  }

  .home-text5 {
    font-size: 15px;
    margin-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .home-but1 {
    width: calc(100% - 48px);
    margin-top: 16px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .section3-home-inner2 {
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 32px;
  }

  .home-text6 {
    font-size: 24px;
    color: #1f1f1f;
    text-align: left;
  }

  .home-but2 {
    width: 100%;
  }

  .home-text7 {
    font-size: 15px;
    text-align: left;
    margin-top: 16px;
  }

  .img-flex {
    display: none;
  }

  .section3-home-inner3 {
    margin-top: 0px;
    flex-direction: column-reverse;
  }

  .home-box {
    margin-left: 24px;
    margin-right: 24px;
    width: calc(100% - 48px);
  }

  .home-text8 {
    font-size: 24px;
    color: #1f1f1f;
    margin-top: 32px;
    width: 100%;
  }

  .home-text9 {
    font-size: 15px;
    margin-top: 16px;
    width: 100%;
  }

  .home-but3 {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .img6 {
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
  }

  .home-box2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .home-text10 {
    font-size: 32px;
  }

  .section3-home {
    padding-bottom: 64px;
  }
}
